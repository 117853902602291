export const transformServices = (
  partnerServices: string[],
  partnerAdditionalServices: string[],
  partnerServiceLabels: Map<string, string>,
): string => {
  const relevantServices: string[] = [...partnerServiceLabels.keys()];

  let services = [
    ...partnerServices
      .filter((value: string) => relevantServices.includes(value))
      .map((service: string) => partnerServiceLabels.get(service)),
  ].join(', ');

  if (partnerAdditionalServices?.length > 0) {
    const additionalServices = [...partnerAdditionalServices].join(', ');

    services = [services, additionalServices].join(', ');
  }

  services = services.replace(/,\s*$/, '');
  return services;
};
