import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../i18n/messages';

export function getCtaLabel(label: string): string {
  switch (label) {
    case 'request testdrive':
      return useI18n(messages.ctaLabelRequestTestdrive);
    case 'schedule service':
      return useI18n(messages.ctaLabelScheduleService);
    case 'contact us':
      return useI18n(messages.ctaLabelContactUs);
    default:
      return label;
  }
}
