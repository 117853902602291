import styled from 'styled-components';
import { constants } from '../constants';

const device = {
  desktop: `(min-width:${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width:${constants.displaySizes.largeDesktop})`,
  smallDesktop: `(min-width:${constants.displaySizes.smallDesktop})`,
  mobile: `(max-width:${constants.displaySizes.mobile})`,
  tablet: `(min-width: ${constants.displaySizes.tablet})`,
  smallTablet: `(max-width: ${constants.displaySizes.tablet})`,
};

interface ContainerProps {
  // eslint-disable-next-line
  variant: any;
  standAlonePBC?: boolean;
}

export const Container = styled.div<ContainerProps>`
  grid-template-columns: auto;
  padding: ${(props) => (props.standAlonePBC ? '40px 16px' : '0px')};
  position: relative;

  @media ${device.smallTablet} {
    column-gap: 17px;
    grid-template-columns: auto auto;
    padding: ${(props) => (props.standAlonePBC ? '40px 16px' : '0px')};
  }
  @media ${device.mobile} {
    padding: ${(props) => (props.standAlonePBC ? '40px 16px' : '0px')};
  }
  @media ${device.tablet} {
    column-gap: 17px;
    grid-template-columns: auto auto;
    padding: ${(props) => (props.standAlonePBC ? '48px 40px' : '0px')};
  }
  @media ${device.smallDesktop} {
    column-gap: 48px;
    padding: ${(props) => (props.standAlonePBC ? '60px 60px' : '0px')};
  }
  @media ${device.desktop} {
    column-gap: 97px;
    padding: ${(props) => (props.standAlonePBC ? '80px 96px' : '0px')};
  }
  @media ${device.largeDesktop} {
    column-gap: 60px;
    width: 1728px;
    padding: ${(props) => (props.standAlonePBC ? '100px 96px' : '0px')};
  }
`;
