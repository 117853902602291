import * as React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import { Button, LayoutItem, Text, useStyledTheme } from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';
import ImprintModal from './ImprintModal';
import { VariantConfig } from './PartnerBusinessCard';
import { GEO_FENCE } from './Icons';
import { useTrackingManager } from '../useTrackingManager';
import { JsonLd } from './Json-ld';
import { PartnerContext } from '../PartnerProvider';
import { useMedia } from '../../lib/hooks';
import { messages } from '../../i18n/messages';
import { openCloseDialog } from '../../lib/openCloseDialog';
import { isTranslationLoaded } from '../../lib/isTranslationLoaded';

interface Props {
  showTitle: boolean;
}

const StyledHeadlineAsLink = styled.div`
  > a {
    border-bottom: 0;
  }
`;

const AddressContainer = styled(LayoutItem)<Props>`
  .pbc-partner-name {
    font-weight: ${(props) => (props.showTitle ? 'normal' : 'bold')};
    margin: 0 0 24px;
  }

  dialog {
    border: none;
    display: block;
    inset: 0;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    padding: 0;
    transition: opacity 0.25s;
  }

  dialog:not([open]) {
    opacity: 0;
    pointer-events: none;
  }

  dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
  dialog:not([open]) {
    background-color: #fff;
  }
  h2,
  h3 {
    margin: 0 0 24px;
  }
`;

const RegNumber = styled(Text)`
  margin: 0 0 24px;
`;

const Wrapper = styled.div`
  max-width: 375px;
`;

interface PartnerBusinessCardTagProps {
  variantConfig: VariantConfig;
  vtpData: {
    imprint?: string;
    registrationNumber?: string;
  };
}

export const PartnerBusinessCardTag: React.FC<PartnerBusinessCardTagProps> = ({
  variantConfig,
  vtpData,
}): React.ReactElement => {
  const { partner } = React.useContext(PartnerContext);
  const pbcRef = React.useRef<HTMLDialogElement>(null);
  const trackingManager = useTrackingManager();
  // eslint-disable-next-line
  const { displayOfficialName, hasOuterSpacing, isGoogleMapLink, isLinkedToPWS, showJsonLd } =
    // eslint-disable-next-line
    variantConfig.contactDetails || variantConfig;

  const partnerName = displayOfficialName ? partner.officialName : partner.nonOfficialName;

  const partnerAddressSize = partner.address.length;

  const partnerAddress =
    partnerAddressSize &&
    partner.address.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={index}>{`${item}${index === partnerAddressSize - 1 ? '' : ', '}`}</Fragment>
    ));

  const theme = useStyledTheme();
  const isViewportXS = useMedia(`only screen and (max-width: ${theme.breakpoints.s}px)`);

  const vtpTitle = useI18n(messages.vtpHeadline);
  const showTitle = isTranslationLoaded(messages.vtpHeadline.id, vtpTitle);
  const nameVariant = () => {
    if (showTitle) return 'copy1';
    return isViewportXS ? 'order2' : 'order2';
  };

  const nameAs = () => {
    if (showTitle) return 'p';
    return isViewportXS ? 'h3' : 'h2';
  };

  return (
    <AddressContainer spacing={hasOuterSpacing ? ['m'] : undefined} showTitle={showTitle}>
      {showTitle && (
        <Text
          as={isViewportXS ? 'h3' : 'h2'}
          variant="order2"
          weight="bold"
          data-testid="vtp-title"
        >
          {vtpTitle}
        </Text>
      )}
      {partner && (
        <>
          {showJsonLd && <JsonLd data={partner} />}
          <Text
            as={nameAs()}
            variant={nameVariant()}
            weight={showTitle ? 'normal' : 'bold'}
            data-testid="name"
            className="pbc-partner-name"
          >
            {isLinkedToPWS && partner.url && !vtpData?.imprint ? (
              <StyledHeadlineAsLink>
                <a href={partner.url} target="_blank" rel="noreferrer">
                  {partnerName}
                </a>
              </StyledHeadlineAsLink>
            ) : (
              partnerName
            )}
          </Text>

          <Wrapper>
            {vtpData?.registrationNumber && (
              <RegNumber variant="copy2">
                {useI18n(messages.registrationNumber)} {vtpData?.registrationNumber}
              </RegNumber>
            )}
            {isGoogleMapLink && (
              <Button
                variant="text"
                icon={GEO_FENCE}
                href={partner.directionsUrl}
                onClick={() =>
                  trackingManager.click(
                    'exit_link',
                    partner.directionsUrl,
                    'link',
                    `${partner.address[0]} ${partner.address[1]}`,
                    partnerName,
                    '1',
                    'location',
                  )
                }
                data-testid="directions"
                spaceStackStart="s"
                spaceStackEnd="l"
              >
                {partnerAddress}
              </Button>
            )}
            {vtpData?.imprint && (
              <Button
                variant="text"
                icon="address-book"
                onClick={() => {
                  openCloseDialog(pbcRef.current as HTMLDialogElement);
                }}
                data-test-id="imprint-cta"
              >
                {useI18n(messages.imprintMessage)}
              </Button>
            )}

            <ImprintModal imprintRef={pbcRef} imprintData={vtpData?.imprint} />
          </Wrapper>
        </>
      )}
    </AddressContainer>
  );
};
