import styled from 'styled-components';
import React from 'react';

type SrcSet = {
  src: string;
  desc: string;
}[];

interface CommonImageProps {
  width?: number;
  height?: number;
  src: string;
  alt?: string;
  loadingAnimation?: 'fade' | 'slide';
}

export interface ImageProps extends CommonImageProps {
  srcSet?: SrcSet;
  sizes?: {
    condition?: string;
    value: string;
  }[];
}

export interface PictureProps extends CommonImageProps {
  sources: {
    srcSet: SrcSet;
    media?: string;
  }[];
}

function srcSetToString(srcSet: SrcSet): string {
  return srcSet.map((item) => `${item.src} ${item.desc}`).join(',');
}

const StyledImage = styled.img<CommonImageProps & { isLoaded: boolean }>`
  max-width: 100%;
  height: auto;
  opacity: ${(props): number => (props.isLoaded ? 1 : 0)};
  transition-timing-function: ${(props) => props.theme.easing};
  transition-duration: ${(props): string => (props.loadingAnimation === 'slide' ? '.5s' : '.3s')};
  transition-property: opacity, transform;
  transform: ${(props): string =>
    props.loadingAnimation === 'slide' && !props.isLoaded ? 'translateY(10%)' : 'none'};
`;

// eslint-disable-next-line react/display-name
const WrappedImage = React.forwardRef<HTMLImageElement, ImageProps>((props: ImageProps, ref) => {
  const { sizes, src, srcSet, ...rest } = props;
  const [isLoaded, setLoaded] = React.useState(false);
  const [prevSrc, setPrevSrc] = React.useState(src);

  const srcSetString = srcSet ? srcSetToString(srcSet) : undefined;

  const sizesString = sizes
    ? sizes.map((item) => `${item.condition || ''} ${item.value}`).join(',')
    : undefined;

  if (prevSrc !== src) {
    // Go back to loading state if src. changed!
    setLoaded(false);
    // Save current src for future comparison
    setPrevSrc(src);
  }
  // test
  return (
    <StyledImage
      ref={ref}
      src={src}
      srcSet={srcSetString}
      sizes={sizesString}
      isLoaded={isLoaded}
      onLoad={(): void => setLoaded(true)}
      onError={(): void => setLoaded(true)}
      {...rest}
    />
  );
});

// eslint-disable-next-line react/display-name
const WrappedPicture = React.forwardRef<HTMLPictureElement, PictureProps>(
  (props: PictureProps, ref) => {
    const { alt, height, sources, src, width, ...rest } = props;
    const [isLoaded, setLoaded] = React.useState(false);

    return (
      <picture ref={ref}>
        {sources.map((source) => {
          const srcSetString = source.srcSet ? srcSetToString(source.srcSet) : undefined;

          return <source key={srcSetString} srcSet={srcSetString} media={source.media} />;
        })}

        <StyledImage
          width={width}
          height={height}
          alt={alt}
          src={src}
          isLoaded={isLoaded}
          onLoad={(): void => setLoaded(true)}
          {...rest}
        />
      </picture>
    );
  },
);

export { WrappedImage as Image, WrappedPicture as Picture };
