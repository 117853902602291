import * as React from 'react';
import { Button, Layout, LayoutItem } from '@audi/audi-ui-react';
import styled, { useTheme } from 'styled-components';
import { useI18n } from '@oneaudi/i18n-context';
import { Image } from './Image';
import { messages } from '../../i18n/messages';

import { useTrackingManager } from '../useTrackingManager';
import { useMedia } from '../../lib/hooks';
import { CtaBar, CtaBarButtonConfig } from './CtaBar';

import { GEO_FENCE, DIRECT_MAIL, VOICE_CALL, WWW_LINK, ADDRESS_BOOK } from './Icons';
import { PartnerContext } from '../PartnerProvider';
import { SocialMediaBar, SocialMediaConfig } from './socialMedia/socialMediaBar';

const StyledImage = styled.figure`
  position: relative;
  margin: 0;
  /* create aspect ratio box with an aspect ratio of 16:9 */
  padding: 0 0 56.25% 0;
  overflow: hidden;

  > img {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
  }
`;

const ColumnItemPicture = styled(LayoutItem)`
  width: 70%;
  margin-inline-end: var(${(props) => props.theme.responsive.spacing.xl});

  @media (max-width: 1023px) {
    margin-inline-end: 0;
    max-width: 100%;
  }
`;

const ColumnItemInformation = styled(LayoutItem)`
  align-self: auto;
  width: 100%;
`;

const ButtonAlignedLeft = styled(Button)`
  text-align: left;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface AdressAndContactProps {
  displayCtaBar?: boolean;
  ctaBarButtonConfig: CtaBarButtonConfig;
  socialMedia: SocialMediaConfig;
  displayEmail?: boolean;
  displayPhone?: boolean;
  isGoogleMapLink?: boolean;
  isNarrow?: boolean;
  isLinkedToPWS?: boolean;
  showMoreInfoLink?: boolean;
  showImage?: boolean;
  showSocialMediaLinks?: boolean;
}

export const AddressAndContact = ({
  displayCtaBar,
  isNarrow,
  ctaBarButtonConfig,
  socialMedia,
  displayEmail,
  displayPhone,
  isGoogleMapLink = false,
  isLinkedToPWS = true,
  showMoreInfoLink = false,
  showImage,
  showSocialMediaLinks,
}: AdressAndContactProps): React.ReactElement => {
  const trackingManager = useTrackingManager();
  const { partner } = React.useContext(PartnerContext);
  const vCardLinkLabel = useI18n(messages.vCardLinkLabel);
  const vCardMoreLinkLabel = useI18n(messages.linkLabel);

  const theme = useTheme();
  const isViewportM = useMedia(`only screen and (min-width: ${theme.breakpoints.m}px)`);

  // TODO Check imgWidth value
  const imgWidth = 328;
  const stackEndMargin = isNarrow ? 'l' : 'xl';
  const partnerName = partner.nameEnhanced;
  const partnerImage = (
    <StyledImage>
      <Image
        loadingAnimation="fade"
        src={`${partner.locationImageUrl}?width=${imgWidth}`}
        data-testid="image"
        alt={partner.officialName}
        sizes={[
          {
            condition: `(max-width: ${imgWidth}px)`,
            value: `${imgWidth}px`,
          },
          {
            condition: '(max-width: 639px)',
            value: '656px',
          },
          {
            condition: '(max-width: 1023px)',
            value: `${imgWidth}px`,
          },
          {
            value: '656px',
          },
        ]}
        srcSet={[
          {
            src: `${partner.locationImageUrl}?width=${imgWidth}`,
            desc: `${imgWidth}w`,
          },
          {
            src: `${partner.locationImageUrl}?width=656`,
            desc: '656w',
          },
        ]}
      />
    </StyledImage>
  );

  return (
    partner && (
      <Layout direction={{ xs: 'column', l: 'row' }}>
        {partner.locationImageUrl && isViewportM && !isNarrow && showImage && (
          <ColumnItemPicture spaceStackEnd="xxl" shrink="0" grow="0" basis="50%">
            {isLinkedToPWS && partner.url ? (
              <a
                onClick={() =>
                  trackingManager.click(
                    'exit_link',
                    partner.url || '',
                    'image',
                    '',
                    partnerName,
                    '',
                    'pws link',
                  )
                }
                href={partner.url}
                target="_blank"
                rel="noreferrer"
              >
                {partnerImage}
              </a>
            ) : (
              partnerImage
            )}
          </ColumnItemPicture>
        )}

        <ColumnItemInformation>
          <Layout direction="column">
            {isGoogleMapLink && (
              <ButtonAlignedLeft
                variant="text"
                icon={GEO_FENCE}
                href={partner.directionsUrl}
                onClick={() =>
                  trackingManager.click(
                    'exit_link',
                    partner.directionsUrl,
                    'link',
                    `${partner.address[0]} ${partner.address[1]}`,
                    partnerName,
                    '1',
                    'location',
                  )
                }
                data-testid="directions"
                spaceStackEnd={stackEndMargin}
              >
                {partner.address[0]}
                <br />
                {partner.address[1]}
              </ButtonAlignedLeft>
            )}

            {displayPhone && (
              <ButtonAlignedLeft
                href={`tel:${partner.phone}`}
                data-testid="phone"
                variant="text"
                icon={VOICE_CALL}
                spaceStackEnd={stackEndMargin}
                onClick={() =>
                  trackingManager.click(
                    'call',
                    partner.directionsUrl,
                    'link',
                    'Anrufen',
                    partnerName,
                    '2',
                    'phone',
                  )
                }
              >
                {partner.phone}
              </ButtonAlignedLeft>
            )}

            {partner.fax && (
              <ButtonAlignedLeft
                href={`tel:${partner.fax}`}
                data-testid="fax"
                variant="text"
                spaceStackEnd={stackEndMargin}
                onClick={() =>
                  trackingManager.click(
                    'call',
                    partner.directionsUrl,
                    'link',
                    'Anrufen',
                    partnerName,
                    '2',
                    'fax',
                  )
                }
              >
                {useI18n(messages.fax)}: {partner.fax}
              </ButtonAlignedLeft>
            )}

            {partner.email && displayEmail && (
              <ButtonAlignedLeft
                href={`mailto:${partner.email}`}
                variant="text"
                icon={DIRECT_MAIL}
                spaceStackEnd={stackEndMargin}
                onClick={() =>
                  trackingManager.click(
                    'email',
                    partner.directionsUrl,
                    'link',
                    partner.email || '',
                    partnerName,
                    '3',
                    'mail',
                  )
                }
              >
                {partner.email}
              </ButtonAlignedLeft>
            )}

            <ButtonAlignedLeft
              spaceStackEnd={stackEndMargin}
              variant="text"
              href={partner.vCardUrl}
              onClick={() =>
                trackingManager.click(
                  'download',
                  partner.vCardUrl,
                  'link',
                  vCardLinkLabel,
                  partnerName,
                  '4',
                  'contact data download',
                )
              }
              data-testid="vcard"
              icon={ADDRESS_BOOK}
            >
              {useI18n(messages.vCardLinkLabel)}
            </ButtonAlignedLeft>

            {showMoreInfoLink && partner.url && (
              <ButtonAlignedLeft
                spaceStackEnd={stackEndMargin}
                variant="text"
                href={partner.url}
                newWindow={isNarrow}
                onClick={() =>
                  trackingManager.click(
                    'exit_link',
                    partner.url || '',
                    'link',
                    vCardMoreLinkLabel,
                    partnerName,
                    '5',
                    'pws link',
                  )
                }
                data-testid="vcard"
                icon={WWW_LINK}
              >
                {useI18n(messages.linkLabel)}
              </ButtonAlignedLeft>
            )}
          </Layout>
          {showSocialMediaLinks && socialMedia && <SocialMediaBar socialMedia={socialMedia} />}
          {displayCtaBar && ctaBarButtonConfig && (
            <CtaBar ctaBarButtonConfig={ctaBarButtonConfig} displayname={partnerName} />
          )}
        </ColumnItemInformation>
      </Layout>
    )
  );
};

AddressAndContact.defaultProps = {
  isNarrow: undefined,
  isLinkedToPWS: undefined,
};
