import * as React from 'react';
import { useRef } from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react';

import { useI18n } from '@oneaudi/i18n-context';
import { determineOpeningHourGroups, OpeningHourGroup } from './OpeningHoursCalculator';
import { messages } from '../../i18n/messages';
import { DepartmentOpeningHours } from './PartnerData';
import {
  getLocalizedWeekdayName,
  getLocalizedTime,
  jsDayToWeekDay,
  isInDayRange,
} from '../../lib/dateUtils';
import { camelize } from '../../lib/utils';
import FeatureServiceContext from '../FeatureServiceContext';
import { LayoutContainer } from './LayoutContainer';

const OpeningHoursRow = styled(LayoutContainer)`
  display: flex;
  width: 100%;
  overflow-wrap: anywhere;
  font-weight: ${(props: { current: boolean }) => (props.current ? '700' : '400')};

  & * {
    font-weight: ${(props: { current: boolean }) => (props.current ? '700' : '400')};
  }
`;

const OpeningHoursCell = styled(LayoutContainer)`
  min-width: 40%;
`;
const OpeningHoursValuesCell = styled(LayoutContainer)`
  min-width: 60%;
`;

export const OpeningHoursDataList = ({
  department,
  now,
}: {
  department: DepartmentOpeningHours;
  now: Date;
}): JSX.Element => {
  const {
    localeService: { language },
  } = React.useContext(FeatureServiceContext);
  const groups = determineOpeningHourGroups(department);
  const grid = useRef<HTMLDivElement>(null);
  // TODO: change that in the API to conform with JS day numbering
  const currentDay = jsDayToWeekDay(now.getDay());
  const handleKeyDown = (e: React.KeyboardEvent): void => {
    // Prevent scrolling
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
    const gridElement = grid.current;
    const cells = gridElement?.querySelectorAll<HTMLDivElement>('[role="gridcell"]');
    const currentFocusedCell = gridElement?.querySelector<HTMLDivElement>('[tabindex="0"]');
    if (!currentFocusedCell) {
      if (e.key === 'ArrowDown' && cells) {
        cells[0].setAttribute('tabIndex', '0');
        cells[0].focus();
      }
    } else {
      switch (e.key) {
        case 'ArrowUp':
          if (
            parseInt(currentFocusedCell.parentElement?.getAttribute('aria-rowindex') || '', 10) > 1
          ) {
            const nextFocusedCell = // eslint-disable-next-line max-len
              currentFocusedCell.parentElement?.previousElementSibling?.querySelector<HTMLDivElement>(
                `[aria-colindex="${currentFocusedCell.getAttribute('aria-colindex')}"]`,
              );
            nextFocusedCell?.setAttribute('tabIndex', '0');
            nextFocusedCell?.focus();
            currentFocusedCell.setAttribute('tabIndex', '-1');
          }
          break;
        case 'ArrowDown':
          if (
            parseInt(currentFocusedCell.parentElement?.getAttribute('aria-rowindex') || '', 10) <
            groups.length
          ) {
            const nextFocusedCell =
              currentFocusedCell.parentElement?.nextElementSibling?.querySelector<HTMLDivElement>(
                `[aria-colindex="${currentFocusedCell.getAttribute('aria-colindex')}"]`,
              );

            nextFocusedCell?.setAttribute('tabIndex', '0');
            nextFocusedCell?.focus();
            currentFocusedCell.setAttribute('tabIndex', '-1');
          }
          break;
        case 'ArrowLeft':
          if (currentFocusedCell.getAttribute('aria-colindex') === '2') {
            (currentFocusedCell.previousSibling as HTMLDivElement).setAttribute('tabIndex', '0');
            (currentFocusedCell.previousSibling as HTMLDivElement).focus();
            currentFocusedCell.setAttribute('tabIndex', '-1');
          }
          break;
        case 'ArrowRight':
          if (currentFocusedCell.getAttribute('aria-colindex') === '1') {
            (currentFocusedCell.nextSibling as HTMLDivElement).setAttribute('tabIndex', '0');
            (currentFocusedCell.nextSibling as HTMLDivElement).focus();
            currentFocusedCell.setAttribute('tabIndex', '-1');
          }
          break;
        default:
          return undefined;
      }
    }
    return undefined;
  };
  return (
    <div
      role="grid"
      ref={grid}
      tabIndex={0}
      aria-labelledby={`openingHoursDepartment-${camelize(
        department.departmentName,
      )} statusDepartment-${camelize(department.departmentName)}`}
      onKeyDown={handleKeyDown}
    >
      {groups.map(({ startDay, endDay, slots }: OpeningHourGroup, j: number) => (
        <OpeningHoursRow
          // eslint-disable-next-line react/no-array-index-key
          key={j}
          role="row"
          aria-rowindex={j + 1}
          spaceStackEnd="s"
          current={isInDayRange(startDay, endDay, currentDay)}
        >
          <OpeningHoursCell role="gridcell" aria-colindex={1} tabIndex={-1} spaceInlineEnd="s">
            {startDay === endDay && (
              <Text as="span">
                {startDay === endDay && getLocalizedWeekdayName(startDay, language)}
              </Text>
            )}
            {startDay !== endDay && (
              <Text as="span">
                {`${getLocalizedWeekdayName(startDay, language)}
                    -
                    ${getLocalizedWeekdayName(endDay, language)}`}
              </Text>
            )}
          </OpeningHoursCell>
          {slots.length === 1 && slots[0].open && (
            <OpeningHoursValuesCell role="gridcell" aria-colindex={2} tabIndex={-1}>
              <Text as="span">
                {`${slots[0].openTime && getLocalizedTime(slots[0].openTime, language)} - ${
                  slots[0].closeTime && getLocalizedTime(slots[0].closeTime, language)
                }`}
              </Text>
            </OpeningHoursValuesCell>
          )}
          {slots.length === 1 && !slots[0].open && (
            <OpeningHoursValuesCell role="gridcell" aria-colindex={2} tabIndex={-1}>
              <Text as="span">{useI18n(messages.openingHoursDayClosed)}</Text>
            </OpeningHoursValuesCell>
          )}
          {slots.length > 1 && (
            <div role="gridcell" aria-colindex={2} tabIndex={-1}>
              {slots.map((slot, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <OpeningHoursValuesCell key={index}>
                  <Text as="span">
                    {`${slot.openTime && getLocalizedTime(slot.openTime, language)} - ${
                      slot.closeTime && getLocalizedTime(slot.closeTime, language)
                    }`}
                  </Text>
                </OpeningHoursValuesCell>
              ))}
            </div>
          )}
        </OpeningHoursRow>
      ))}
    </div>
  );
};
