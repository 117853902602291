/**
 * Context Provider for Feature App
 * @see: https://github.com/volkswagen-onehub/audi-feature-app-hello-world/blob/master/src/provider.tsx
 */
import React, { useMemo } from 'react';
import { ThemeProvider } from '@audi/audi-ui-react';
import { InViewContextProvider } from '@oneaudi/feature-app-utils';
import {
  ContentService,
  Logger,
  QueryCacheService,
  TrackingService,
  GfaLocaleServiceV1,
} from './feature-service-types';
import FeatureServiceContext from './FeatureServiceContext';

export interface Env {
  dealerSearchServiceEndpointUrl: string;
}
export interface ProviderProps {
  children?: React.ReactNode;
  localeService: GfaLocaleServiceV1;
  logger: Logger;
  trackingService?: TrackingService;
  contentService?: ContentService;
  queryCacheService: QueryCacheService;
}

export const FeatureAppProvider = (props: ProviderProps): React.ReactElement => {
  const { children, localeService, logger, trackingService, contentService, queryCacheService } =
    props;

  const value = useMemo(
    () => ({ localeService, logger, trackingService, contentService, queryCacheService }),
    [],
  );

  return (
    // TODO - Investigate tsc error (invalid children property). Remove below comment and run tsc to test.
    <InViewContextProvider logger={logger}>
      <FeatureServiceContext.Provider value={value}>
        <ThemeProvider>{children}</ThemeProvider>
      </FeatureServiceContext.Provider>
    </InViewContextProvider>
  );
};
