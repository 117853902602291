export const constants = {
  displaySizes: {
    smallMobile: '320px',
    mobile: '374px',
    tablet: '768px',
    smallDesktop: '1024px',
    desktop: '1440px',
    largeDesktop: '1920px',
  },
};

export const layoutStyleStrings = {
  selectedInfoNarrow: 'Show selected dealer info (narrow layout)',
  selectedInfoLong: 'Show selected dealer info (long layout)',
  dealerAddressAndContactInfo: 'Show dealer name, address, and contact information only',
  dealerAndAddress: 'Show dealer name and address only',
};

export const sizes = {
  desktop: {
    [layoutStyleStrings.selectedInfoNarrow]: '1400px',
    [layoutStyleStrings.selectedInfoLong]: '1200px',
    [layoutStyleStrings.dealerAddressAndContactInfo]: '160px',
    [layoutStyleStrings.dealerAndAddress]: '94px',
  },
  tablet: {
    [layoutStyleStrings.selectedInfoNarrow]: '1300px',
    [layoutStyleStrings.selectedInfoLong]: '1300px',
    [layoutStyleStrings.dealerAddressAndContactInfo]: '160px',
    [layoutStyleStrings.dealerAndAddress]: '64px',
  },
  mobile: {
    [layoutStyleStrings.selectedInfoNarrow]: '1200px',
    [layoutStyleStrings.selectedInfoLong]: '800px',
    [layoutStyleStrings.dealerAddressAndContactInfo]: '160px',
    [layoutStyleStrings.dealerAndAddress]: '110px',
  },
};
