import * as React from 'react';

import styled from 'styled-components';
import { Text, Theme, TextProps, useStyledTheme } from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';
import FeatureServiceContext from '../FeatureServiceContext';
import { messages } from '../../i18n/messages';
import { getLocalizedWeekdayName, getLocalizedTime } from '../../lib/dateUtils';
import { DepartmentOpeningHours } from './PartnerData';
import { getDepartmentOpeningStatus, OpeningState } from './OpeningHoursCalculator';
import { camelize } from '../../lib/utils';
import { LayoutContainer } from './LayoutContainer';
import { useMedia } from '../../lib/hooks';

type StatusIndicatorProps = TextProps & {
  color: 'yellow' | 'red' | 'green';
  theme: Theme;
};
// eslint-disable-next-line react/jsx-props-no-spreading
const StatusIndicator = styled((props) => <Text as="span" {...props} />)`
  border-radius: 9999px;
  width: 11px;
  height: 11px;
  line-height: 1;
  content: '';
  margin-bottom: 3px;
  display: inline-block;
  vertical-align: middle;
  background-color: ${({ theme, color }: StatusIndicatorProps) =>
    color && theme?.colors?.base?.signal[color]
      ? `var(${theme?.colors?.base?.signal[color]})`
      : color};
`;

export const OpeningHoursLabel = ({
  department,
  now,
  isNarrow,
}: {
  department: DepartmentOpeningHours;
  now: Date;
  isNarrow?: boolean;
}): JSX.Element => {
  const {
    localeService: { language },
  } = React.useContext(FeatureServiceContext);
  const openingStatus = getDepartmentOpeningStatus(department, now);
  let openStatusLabel: React.ReactElement;
  let openStatusIndicator;

  const willOpenAtDayNameString =
    openingStatus.next?.day && getLocalizedWeekdayName(openingStatus.next?.day, language);

  const willOpenAtTime =
    openingStatus.next?.openTime && getLocalizedTime(openingStatus.next?.openTime, language);

  const theme = useStyledTheme();
  const isViewportM = useMedia(`only screen and (max-width: ${theme.breakpoints.m}px)`);
  const spaceEnd = isViewportM || isNarrow ? undefined : 'xl';

  switch (openingStatus.status) {
    case OpeningState.Open:
      openStatusLabel = (
        <Text as="span">
          {useI18n(messages.openingHoursLabelOpenUntil)}
          {` ${
            openingStatus.current?.closeTime &&
            getLocalizedTime(openingStatus.current?.closeTime, language)
          }`}
        </Text>
      );
      openStatusIndicator = <StatusIndicator spaceInlineEnd="s" color="green" />;
      break;
    case OpeningState.OpenClosingSoon:
      openStatusLabel = (
        <Text as="span">
          {useI18n(messages.openingHoursLabelClosesSoon)}

          {` ${
            openingStatus.current?.closeTime &&
            getLocalizedTime(openingStatus.current?.closeTime, language)
          }`}
        </Text>
      );
      openStatusIndicator = <StatusIndicator spaceInlineEnd="s" color="yellow" />;
      break;
    case OpeningState.Closed:
      openStatusLabel = (
        <Text as="span">
          {useI18n(messages.openingHoursLabelClosed)}

          {`, `}

          {useI18n(messages.openingHoursLabelWillOpenAt)}

          {` ${willOpenAtDayNameString} ${willOpenAtTime}`}
        </Text>
      );
      openStatusIndicator = <StatusIndicator spaceInlineEnd="s" color="red" />;
      break;
    default:
      openStatusIndicator = null;
      openStatusLabel = <Text as="span">{useI18n(messages.openingHoursLabelStatusIsUnknown)}</Text>;
      break;
  }
  return (
    <LayoutContainer spaceStackEnd={spaceEnd}>
      <Text
        as="span"
        weight="bold"
        id={`openingHoursDepartment-${camelize(department.departmentName)}`}
        spaceStackEnd="xxs"
      >
        {department.departmentName}
      </Text>
      <Text as="p" id={`statusDepartment-${camelize(department.departmentName)}`}>
        {openStatusIndicator}
        {openStatusLabel}
      </Text>
    </LayoutContainer>
  );
};

// import * as React from 'react';

// import styled from 'styled-components';
// import { Text, Theme, TextProps, useStyledTheme } from '@audi/audi-ui-react';
// import { useI18n } from '@oneaudi/i18n-context';
// import FeatureServiceContext from '../FeatureServiceContext';
// import { messages } from '../../i18n/messages';
// import { getLocalizedWeekdayName, getLocalizedTime } from '../../lib/dateUtils';
// import { DepartmentOpeningHours } from './PartnerData';
// import { getDepartmentOpeningStatus, OpeningState } from './OpeningHoursCalculator';
// import { camelize } from '../../lib/utils';
// import { LayoutContainer } from './LayoutContainer';
// import { useMedia } from '../../lib/hooks';

// type StatusIndicatorProps = TextProps & {
//   color: 'yellow' | 'red' | 'green';
//   theme: Theme;
// };
// // eslint-disable-next-line react/jsx-props-no-spreading
// const StatusIndicator = styled((props) => <Text as="span" {...props} />)`
//   border-radius: 9999px;
//   width: 11px;
//   height: 11px;
//   line-height: 1;
//   content: '';
//   margin-bottom: 3px;
//   display: inline-block;
//   vertical-align: middle;
//   background-color: ${({ theme, color }: StatusIndicatorProps) =>
//     color && theme?.colors?.base?.signal[color]
//       ? `var(${theme?.colors?.base?.signal[color]})`
//       : color};
// `;

// export const OpeningHoursLabel = ({
//   department,
//   now,
//   isNarrow,
// }: {

//   department: DepartmentOpeningHours;
//   now: Date;
//   isNarrow?: boolean;
// }): JSX.Element => {
//   console.log("now",now)
//   const {
//     localeService: { language },
//   } = React.useContext(FeatureServiceContext);
//   const openingStatus = getDepartmentOpeningStatus(department, now);
//   // const closeDate:any = (now, openingStatus.next?.closeTime);
//   // console.log("closeDate",closeDate)

//       // let subtractAnHourFromCloseDate = closeDate.getTime() - 1;
//       // console.log("subtractAnHourFromCloseDate",subtractAnHourFromCloseDate)

//   let openStatusLabel: React.ReactElement;
//   let openStatusIndicator;

//   const willOpenAtDayNameString =
//     openingStatus.next?.day && getLocalizedWeekdayName(openingStatus.next?.day, language);
// console.log("willOpenAtDayNameString",willOpenAtDayNameString)
//   const willOpenAtTime =
//     openingStatus.next?.openTime && getLocalizedTime(openingStatus.next?.openTime, language);
//     console.log("willOpenAtTime",willOpenAtTime)
//     const willCloseAtTime =
//     openingStatus.next?.closeTime && getLocalizedTime(openingStatus.next?.closeTime, language);
//     console.log("willCloseAtTime",willCloseAtTime)

//   const theme = useStyledTheme();
//   const isViewportM = useMedia(`only screen and (max-width: ${theme.breakpoints.m}px)`);
//   const spaceEnd = isViewportM || isNarrow ? undefined : 'xl';

//   switch (openingStatus.status) {
//     case OpeningState.Open:
//       openStatusLabel = (
//         <Text as="span">
//           {useI18n(messages.openingHoursLabelOpenUntil)}
//           {` ${
//             openingStatus.current?.closeTime &&
//             getLocalizedTime(openingStatus.current?.closeTime, language)
//           }`}
//         </Text>
//       );
//       openStatusIndicator = <StatusIndicator spaceInlineEnd="s" color="green" />;
//       break;
//     case OpeningState.OpenClosingSoon:
//       openStatusLabel = (
//         <Text as="span">
//           {useI18n(messages.openingHoursLabelClosesSoon)}

//           {` ${
//             openingStatus.current?.closeTime &&
//             getLocalizedTime(openingStatus.current?.closeTime, language)
//           }`}
//         </Text>
//       );
//       openStatusIndicator = <StatusIndicator spaceInlineEnd="s" color="yellow" />;
//       break;
//     case OpeningState.Closed:
//       openStatusLabel = (
//         <Text as="span">
//           {useI18n(messages.openingHoursLabelClosed)}

//           {`, `}

//           {useI18n(messages.openingHoursLabelWillOpenAt)}

//           {` ${willOpenAtDayNameString} ${willOpenAtTime}`}
//         </Text>
//       );
//       openStatusIndicator = <StatusIndicator spaceInlineEnd="s" color="red" />;
//       break;
//     default:
//       openStatusIndicator = null;
//       openStatusLabel = <Text as="span">{useI18n(messages.openingHoursLabelStatusIsUnknown)}</Text>;
//       break;
//   }
//   return (
//     <LayoutContainer spaceStackEnd={spaceEnd}>
//       <Text
//         as="span"
//         weight="bold"
//         id={`openingHoursDepartment-${camelize(department.departmentName)}`}
//         spaceStackEnd="xxs"
//       >
//         {department.departmentName}
//       </Text>
//       <Text as="p" id={`statusDepartment-${camelize(department.departmentName)}`}>
//         {openStatusIndicator}
//         {openStatusLabel}
//       </Text>
//     </LayoutContainer>
//   );
// };
