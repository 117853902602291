import * as React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { Container, Content, ImprintContainer, CloseButton, Title, ImprintText } from './style';
import { openCloseDialog } from '../../../lib/openCloseDialog';
import { messages } from '../../../i18n/messages';

interface ImprintProps {
  imprintData: string | undefined;
  imprintRef: React.RefObject<HTMLDialogElement>;
}

export const ImprintModal: React.FC<ImprintProps> = ({ imprintData, imprintRef }) => {
  const renderHTML = (rawHTML: string) =>
    React.createElement('span', { dangerouslySetInnerHTML: { __html: rawHTML } });

  return (
    <Container data-test-id="imprint-modal" id="dialog" ref={imprintRef}>
      <Content>
        <ImprintContainer data-test-id="imprint-text">
          <CloseButton
            variant="icon-tertiary"
            icon="cancel"
            onClick={() => {
              openCloseDialog(imprintRef.current as HTMLDialogElement);
            }}
            data-test-id="imprint-closeButton"
          />
          <Title variant="order2" weight="bold">
            {useI18n(messages.imprintTitle)}
          </Title>
          {imprintData && <ImprintText variant="copy2">{renderHTML(imprintData)}</ImprintText>}
        </ImprintContainer>
      </Content>
    </Container>
  );
};

export default ImprintModal;
