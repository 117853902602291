import { WeekDay } from '../app/components/PartnerData';

const week: WeekDay[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const jsDayToWeekDay = (jsDay: number): WeekDay => week[jsDay];

export const weekDayToJsDay = (weekDay: WeekDay): number => week.indexOf(weekDay);

export const getNextWeekDay = (currentDay: WeekDay): WeekDay =>
  week[week.indexOf(currentDay) + 1] || week[0];

export const getPreviousWeekDay = (currentDay: WeekDay): WeekDay =>
  week[week.indexOf(currentDay) - 1] || week[6];

export const isInDayRange = (startDay: WeekDay, endDay: WeekDay, currentDay: WeekDay): boolean => {
  if (currentDay === startDay || currentDay === endDay) {
    return true;
  }
  const startIndex = week.indexOf(startDay);
  const endIndex = week.indexOf(endDay);
  const currentIndex = week.indexOf(currentDay);

  if (startIndex <= endIndex) {
    return currentIndex >= startIndex && currentIndex <= endIndex;
  }
  return currentIndex >= startIndex || currentIndex <= endIndex;
};

export const createDateWithTime = (baseDate: Date, time?: string): Date => {
  const dayStr = baseDate.getDate() < 10 ? `0${baseDate.getDate()}` : baseDate.getDate();
  const month = baseDate.getMonth() + 1;
  const monthStr = month < 10 ? `0${month}` : month;

  return time ? new Date(`${baseDate.getFullYear()}-${monthStr}-${dayStr}T${time}`) : baseDate;
};

export const getLocalizedWeekdayName = (weekDay: WeekDay, locale: string): string => {
  const date = new Date(2020, 2, weekDayToJsDay(weekDay) + 1);
  return new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(date);
};

export const getLocalizedTime = (timeString: string, locale: string): string => {
  const date = createDateWithTime(new Date(), timeString);
  return new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: 'numeric' }).format(date);
};
