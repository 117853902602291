// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const queryString = (partnerId: string) => {
  return `query Dealer {
  dealerById(dealerId: "${partnerId}") {
    dealerId
    name
    phone
    url
    email
    address
    vCardUrl
    services
    longitude
    latitude
    openingHours {
      openingHoursNote
      departments {
        id
        departmentName
        departmentOpeningHoursNote
        openingHours {
          id
          open
          timeRanges {
            openTime
            closeTime
          }
        }
      }
    }
    additionalData {
      displayName
      locationName
      locationImage
      additionalServices
      additionalUrl
    }
  }
}`;
};
