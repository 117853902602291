import { ApolloClient, InMemoryCache } from '@apollo/client';

export const useClient = (locale: string) => {
  const client = new ApolloClient({
    ssrMode: true,
    uri: 'https://dealer-graphql.apps.emea.vwapps.io/',
    cache: new InMemoryCache(),
    headers: {
      clientId: 'dg552wqh5t',
      translation: locale,
    },
  });

  return client;
};
