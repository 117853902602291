/* eslint-disable @typescript-eslint/no-non-null-assertion */
/**
 * This is the entry point for Feature Hub App integration
 */
import * as React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import { QueryCache } from 'react-query';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import I18nContextComponent from '@oneaudi/i18n-context';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { ApolloProvider } from '@apollo/client';
import {
  AsyncSsrManager,
  ContentService,
  EnvConfigService,
  GfaLocaleServiceV1,
  Logger,
  PersonalizationService,
  SerializedStateManager,
  TrackingService,
  QueryCacheService,
} from './feature-service-types';
import { FeatureAppProvider } from './FeatureAppProvider';
import { ConfigProps } from './components/PartnerBusinessCard';
import FeatureApp from './FeatureApp';
import { i18nScope } from '../i18n/index';
import { useClient } from '../lib/client';

export const name = 'fa-partner-business-card';
interface RequiredFeatureServiceDependencies extends FeatureServices {
  readonly 'audi:envConfigService'?: EnvConfigService;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'dbad:audi-i18n-service'?: I18NServiceV1;
}
interface OptionalFeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'audi-tracking-service'?: TrackingService;
  readonly 'audi-personalization-service'?: PersonalizationService;
  readonly 's2:async-ssr-manager'?: AsyncSsrManager;
  readonly 's2:serialized-state-manager'?: SerializedStateManager;
  readonly 'audi-ddmc:query-cache-service'?: QueryCacheService;
  readonly 'audi-content-service'?: ContentService;
  readonly 'audi-render-mode-service'?: RenderModeServiceV1;
}

export interface FeatureServiceDependencies
  extends RequiredFeatureServiceDependencies,
    OptionalFeatureServiceDependencies,
    FeatureServices {}

export interface Env {
  dealerSearchServiceEndpointUrl: string;
}

export interface FeatureAppAsyncState {
  env: Env;
}

export async function fetchEnv(envConfigService: EnvConfigService) {
  const configName = 'partner-business-card';
  const env = await envConfigService.getConfiguration<Env>(configName);

  if (!env) {
    throw new Error(`Error while fetching ${configName} environment configuration`);
  }

  return env;
}

/**
 * It holds a function to retrieve the State needed by the feature app when
 * it is rendered on the server
 * It holds the State object created from the serialized state on the client
 */
export type FeatureAppAsyncStateHolder =
  | (() => Promise<FeatureAppAsyncState>)
  | FeatureAppAsyncState
  | undefined;

async function createFeatureAppAsyncState(
  envConfigService: EnvConfigService,
): Promise<FeatureAppAsyncState> {
  const envServiceConfig = await fetchEnv(envConfigService);
  const env = {
    ...envServiceConfig,
  };
  return { env };
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  // (!) Keep in sync with README and feature-service-types.ts!
  dependencies: {
    externals: {
      react: '^16.13.0||^17.0.2||^18.x.x',
      'styled-components': '*',
      '@feature-hub/react': '^3.x.x',
      '@audi/audi-ui-react': '^3.x.x',
    },
    featureServices: {
      'audi:envConfigService': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
    },
  },

  // (!) Keep in sync with README and feature-service-types.ts!
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-tracking-service': '^2.1.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-ddmc:query-cache-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({
    featureServices,
    config,
  }: FeatureAppEnvironment<FeatureServiceDependencies, ConfigProps>) => {
    console.log('🚀 ~ config:', config);
    const envConfigService = featureServices['audi:envConfigService'] as EnvConfigService;
    const localeService = featureServices['gfa:locale-service'];

    const logger = featureServices['s2:logger'] || console;
    const i18nData = featureServices['dbad:audi-i18n-service'] as I18NServiceV1;
    const trackingService = featureServices['audi-tracking-service'];

    const contentService = featureServices['audi-content-service'];

    if (typeof window !== 'undefined' && trackingService) {
      trackingService.featureAppName = name;
    }

    let asyncStateHolder: FeatureAppAsyncStateHolder;

    // SSR
    // --------------------------------------------------------------------
    const asyncSSRManager = featureServices['s2:async-ssr-manager'];

    const serializedStateManager = featureServices['s2:serialized-state-manager'];

    if (asyncSSRManager) {
      asyncSSRManager.scheduleRerender(
        (async () => {
          asyncStateHolder = await createFeatureAppAsyncState(envConfigService);
          serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
        })(),
      );
    }

    const queryCacheService = featureServices['audi-ddmc:query-cache-service'] || {
      queryCache: new QueryCache(),
    };
    const client = useClient(localeService.countryCode);
    return {
      render() {
        return (
          <UniversalEditorProvider
            contentService={featureServices['audi-content-service']!}
            renderModeService={featureServices['audi-render-mode-service']}
          >
            <FeatureAppProvider
              localeService={localeService}
              logger={logger}
              trackingService={trackingService}
              contentService={contentService}
              queryCacheService={queryCacheService}
              // envConfigService={envConfigService}
            >
              <ApolloProvider client={client}>
                <I18nContextComponent
                  i18nData={i18nData}
                  language={localeService.language}
                  scopes={[i18nScope]}
                >
                  <FeatureApp config={config} />
                </I18nContextComponent>
              </ApolloProvider>
            </FeatureAppProvider>
          </UniversalEditorProvider>
        );
      },
    };
  },
};

// eslint-disable-next-line import/no-default-export
export default featureAppDefinition;
