/* eslint-disable react/jsx-props-no-spreading */
/**
 * Context for Feature App with FA Services
 * @see: https://github.com/volkswagen-onehub/audi-feature-app-hello-world/blob/master/src/context.tsx
 */
import * as React from 'react';

import {
  ContentService,
  GfaLocaleServiceV1,
  Logger,
  PersonalizationService,
  TrackingService,
  QueryCacheService,
} from './feature-service-types';

export interface ContextValue {
  localeService: GfaLocaleServiceV1;
  logger: Logger;
  trackingService?: TrackingService;
  personalizationService?: PersonalizationService;
  contentService?: ContentService;
  queryCacheService?: QueryCacheService;
}

const FeatureServiceContext = React.createContext<ContextValue>({
  localeService: {} as GfaLocaleServiceV1,
  logger: console,
  contentService: {} as ContentService,
});

export default FeatureServiceContext;
