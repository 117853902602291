import * as React from 'react';
import { LayoutProps } from '@audi/audi-ui-react';
import { v4 as uuidGenerator } from 'uuid';
import { UeContainer, UeReference } from '@oneaudi/falcon-tools';
import { SocialButton, StyleSocialLinkLayout } from './style';
import {
  FACEBOOK_ICON,
  TWITTER_ICON,
  INSTAGRAM_ICON,
  LINKENDIN_ICON,
  YOUTUBE_ICON,
  VIMEO_ICON,
} from '../Icons';

export type SocialMediaConfig = {
  facebook: string;
  facebookUrl?: string;
  instagram: string;
  instagramUrl: string;
  twitter: string;
  twitterUrl: string;
  linkedln: string;
  linkedlnUrl: string;
  youtube: string;
  youtubeUrl: string;
  vimeo: string;
  vimeoUrl: string;
}[];

export type SocialBarProps = LayoutProps & {
  socialMedia: SocialMediaConfig;
};
// eslint-disable-next-line
export const SocialMediaBar = ({ socialMedia }: SocialBarProps): React.ReactElement => {
  return (
    <UeContainer propertyPath="variantConfig_socialMedia" label="Social Media">
      <StyleSocialLinkLayout>
        {socialMedia.map((socialMediaLinkInfo, index: number) => {
          return (
            <React.Fragment key={uuidGenerator()}>
              {socialMediaLinkInfo.facebook ? (
                <UeReference propertyPath={`variantConfig_socialMedia.${index}`}>
                  <SocialButton
                    variant="text"
                    href={socialMediaLinkInfo.facebookUrl}
                    spaceStackStart="m"
                    target="_blank"
                  >
                    {FACEBOOK_ICON}
                  </SocialButton>
                </UeReference>
              ) : (
                ''
              )}
              {socialMediaLinkInfo.instagram ? (
                <UeReference propertyPath={`variantConfig_socialMedia.${index}`}>
                  <SocialButton
                    variant="text"
                    href={socialMediaLinkInfo.instagramUrl}
                    spaceStackStart="m"
                    target="_blank"
                    className="arrow"
                  >
                    {INSTAGRAM_ICON}
                  </SocialButton>
                </UeReference>
              ) : (
                ''
              )}
              {socialMediaLinkInfo.twitter ? (
                <UeReference propertyPath={`variantConfig_socialMedia.${index}`}>
                  <SocialButton
                    variant="text"
                    href={socialMediaLinkInfo.twitterUrl}
                    spaceStackStart="m"
                    target="_blank"
                  >
                    {TWITTER_ICON}
                  </SocialButton>
                </UeReference>
              ) : (
                ''
              )}
              {socialMediaLinkInfo.linkedln ? (
                <UeReference propertyPath={`variantConfig_socialMedia.${index}`}>
                  <SocialButton
                    variant="text"
                    href={socialMediaLinkInfo.linkedlnUrl}
                    spaceStackStart="m"
                    target="_blank"
                  >
                    {LINKENDIN_ICON}
                  </SocialButton>
                </UeReference>
              ) : (
                ''
              )}
              {socialMediaLinkInfo.youtube ? (
                <UeReference propertyPath={`variantConfig_socialMedia.${index}`}>
                  <SocialButton
                    variant="text"
                    href={socialMediaLinkInfo.youtubeUrl}
                    spaceStackStart="m"
                    target="_blank"
                  >
                    {YOUTUBE_ICON}
                  </SocialButton>
                </UeReference>
              ) : (
                ''
              )}
              {socialMediaLinkInfo.vimeo ? (
                <UeReference propertyPath={`variantConfig_socialMedia.${index}`}>
                  <SocialButton
                    variant="text"
                    href={socialMediaLinkInfo.vimeoUrl}
                    spaceStackStart="m"
                    target="_blank"
                  >
                    {VIMEO_ICON}
                  </SocialButton>
                </UeReference>
              ) : (
                ''
              )}
            </React.Fragment>
          );
        })}
      </StyleSocialLinkLayout>
    </UeContainer>
  );
};
