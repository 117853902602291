import { DayOpeningHours, DepartmentOpeningHours, TimeRange, WeekDay } from './PartnerData';
import {
  createDateWithTime as createDate,
  jsDayToWeekDay,
  getNextWeekDay,
  getPreviousWeekDay,
} from '../../lib/dateUtils';

// eslint-disable-next-line no-shadow
export enum OpeningState {
  Open,
  OpenClosingSoon,
  Closed,
  Unknown,
}

export type OpeningStatus = {
  status: OpeningState;
  current?: OpeningHourSlot;
  next?: OpeningHourSlot;
};

export type OpeningHourGroup = {
  startDay: WeekDay;
  endDay: WeekDay;
  slots: OpeningHourSlot[];
};

export type OpeningHourSlot = {
  day?: WeekDay;
  open: boolean;
  openTime?: string;
  closeTime?: string;
};

const MILLISECONDS_PER_HOUR = 3600000;

const isSlotOpen = (openingHourSlot: OpeningHourSlot, date: Date): boolean => {
  let correctDayOfWeek = true;
  if (openingHourSlot.day) {
    correctDayOfWeek = openingHourSlot.day === jsDayToWeekDay(date.getDay());
  }

  const openDate = createDate(date, openingHourSlot.openTime);
  const closeDate = createDate(date, openingHourSlot.closeTime);

  const dateWithinOpeningHours = !!openDate && openDate <= date && !!closeDate && date <= closeDate;

  return correctDayOfWeek && openingHourSlot.open && dateWithinOpeningHours;
};

const isOpen = (openingHours: DayOpeningHours, date: Date): boolean => {
  const correctDayOfWeek = openingHours.id === jsDayToWeekDay(date.getDay());

  const anySlotOpen =
    openingHours.timeRanges
      ?.map(
        (timeRange): OpeningHourSlot => ({
          day: openingHours.id,
          open: openingHours.open,
          openTime: timeRange.openTime,
          closeTime: timeRange.closeTime,
        }),
      )
      .some((slot) => isSlotOpen(slot, date)) || false;

  return correctDayOfWeek && openingHours.open && anySlotOpen;
};

export const isOpenAt = (department: DepartmentOpeningHours, date: Date): boolean =>
  department.openingHours.some((day) => isOpen(day, date));

export const findOpeningHoursForDay = (
  department: DepartmentOpeningHours,
  day: WeekDay,
): DayOpeningHours | undefined => department.openingHours.find((value) => value.id === day);

export const findCurrentOpeningHours = (
  department: DepartmentOpeningHours,
  date: Date,
): OpeningHourSlot | undefined => {
  const currentWeekDay = jsDayToWeekDay(date.getDay());
  const currentDayOpeningHours = findOpeningHoursForDay(department, currentWeekDay);
  return currentDayOpeningHours?.timeRanges
    ?.map(
      (timeRange): OpeningHourSlot => ({
        day: currentDayOpeningHours.id,
        open: currentDayOpeningHours.open,
        openTime: timeRange.openTime,
        closeTime: timeRange.closeTime,
      }),
    )
    .find((slot) => isSlotOpen(slot, date));
};

const byCloseTime = (a: TimeRange, b: TimeRange): number => {
  if (!a.closeTime && b.closeTime) {
    return 1;
  }
  if (a.closeTime && !b.closeTime) {
    return -1;
  }
  if (!a.closeTime && !b.closeTime) {
    return 0;
  }
  const closeTime1 = a.closeTime || '';
  const closeTime2 = b.closeTime || '';
  if (closeTime1 < closeTime2) {
    return 1;
  }
  if (closeTime1 > closeTime2) {
    return -1;
  }
  return 0;
};
export const findPreviousOpeningHours = (
  department: DepartmentOpeningHours,
  date: Date,
): OpeningHourSlot | undefined => {
  const currentWeekDay = jsDayToWeekDay(date.getDay());
  const currentDayOpeningHours = findOpeningHoursForDay(department, currentWeekDay);

  const previousOpeningHours = currentDayOpeningHours?.timeRanges
    ?.sort(byCloseTime)
    .find((value) => {
      const closeDate = createDate(date, value.closeTime);
      return !!closeDate && date > closeDate;
    });
  if (currentDayOpeningHours?.open && previousOpeningHours) {
    return {
      day: currentDayOpeningHours.id,
      open: currentDayOpeningHours.open,
      openTime: previousOpeningHours.openTime,
      closeTime: previousOpeningHours.closeTime,
    };
  }
  let previousDay = getPreviousWeekDay(currentWeekDay);
  while (previousDay !== currentWeekDay) {
    const previousDayOpeningHours = findOpeningHoursForDay(department, previousDay);
    if (previousDayOpeningHours?.open) {
      const sortedSlots = previousDayOpeningHours?.timeRanges?.sort(byCloseTime) || [];
      if (sortedSlots.length > 0) {
        return {
          day: previousDayOpeningHours.id,
          open: previousDayOpeningHours.open,
          openTime: sortedSlots[0].openTime,
          closeTime: sortedSlots[0].closeTime,
        };
      }
    }
    previousDay = getPreviousWeekDay(previousDay);
  }
  return undefined;
};

const byOpenTime = (a: TimeRange, b: TimeRange): number => {
  if (!a.openTime && b.openTime) {
    return 1;
  }
  if (a.openTime && !b.openTime) {
    return -1;
  }
  if (!a.openTime && !b.openTime) {
    return 0;
  }
  const openTime1 = a.openTime || '';
  const openTime2 = b.openTime || '';
  if (openTime1 < openTime2) {
    return -1;
  }
  if (openTime1 > openTime2) {
    return 1;
  }
  return 0;
};

export const findNextOpeningHours = (
  department: DepartmentOpeningHours,
  date: Date,
): OpeningHourSlot | undefined => {
  const currentWeekDay = jsDayToWeekDay(date.getDay());
  const currentDayOpeningHours = findOpeningHoursForDay(department, currentWeekDay);
  const nextOpeningHours = currentDayOpeningHours?.timeRanges?.sort(byOpenTime).find((value) => {
    const openDate = createDate(date, value.openTime);
    return !!openDate && date < openDate;
  });
  if (currentDayOpeningHours?.open && nextOpeningHours) {
    return {
      day: currentDayOpeningHours.id,
      open: currentDayOpeningHours.open,
      openTime: nextOpeningHours.openTime,
      closeTime: nextOpeningHours.closeTime,
    };
  }

  let nextDay = getNextWeekDay(currentWeekDay);
  while (nextDay !== currentWeekDay) {
    const nextDayOpeningHours = findOpeningHoursForDay(department, nextDay);
    if (nextDayOpeningHours?.open) {
      const sortedSlots = nextDayOpeningHours?.timeRanges?.sort(byOpenTime) || [];
      if (sortedSlots.length > 0) {
        return {
          day: nextDayOpeningHours.id,
          open: nextDayOpeningHours.open,
          openTime: sortedSlots[0].openTime,
          closeTime: sortedSlots[0].closeTime,
        };
      }
    }
    nextDay = getNextWeekDay(nextDay);
  }
  return undefined;
};

export const getDepartmentOpeningStatus = (
  department: DepartmentOpeningHours,
  date: Date,
): OpeningStatus => {
  const result: OpeningStatus = {
    status: OpeningState.Unknown,
    current: undefined,
    next: undefined,
  };
  if (department.openingHours?.length > 0) {
    result.status = isOpenAt(department, date) ? OpeningState.Open : OpeningState.Closed;
    if (result.status === OpeningState.Open) {
      result.current = findCurrentOpeningHours(department, date);
      const closeDate = createDate(date, result.current?.closeTime);
      if (closeDate && closeDate.getTime() - date.getTime() < MILLISECONDS_PER_HOUR) {
        result.status = OpeningState.OpenClosingSoon;
      }
    } else {
      result.current = findPreviousOpeningHours(department, date);
    }
    result.next = findNextOpeningHours(department, date);
  }
  return result;
};

const getSlots = (dayOpeningHours?: DayOpeningHours): OpeningHourSlot[] => {
  const slots: OpeningHourSlot[] = [];
  if (dayOpeningHours?.timeRanges && dayOpeningHours.timeRanges.length > 0) {
    dayOpeningHours?.timeRanges?.sort(byOpenTime).forEach((slot) => {
      slots.push({
        open: dayOpeningHours.open,
        openTime: dayOpeningHours.open ? slot.openTime : undefined,
        closeTime: dayOpeningHours.open ? slot.closeTime : undefined,
      });
    });
  } else {
    slots.push({ open: false });
  }
  return slots;
};

export const determineOpeningHourGroups = (
  department: DepartmentOpeningHours,
): OpeningHourGroup[] => {
  const groups: OpeningHourGroup[] = [];
  let currentGroup;
  const startOfWeek = 'monday';

  let currentDay: WeekDay = startOfWeek;
  do {
    const currentDayOpeningHours = findOpeningHoursForDay(department, currentDay);

    const currentSlots = getSlots(currentDayOpeningHours);
    if (!currentGroup) {
      currentGroup = {
        startDay: currentDay,
        endDay: currentDay,
        slots: currentSlots,
      };
      groups.push(currentGroup);
    } else if (JSON.stringify(currentSlots) === JSON.stringify(currentGroup.slots)) {
      currentGroup.endDay = currentDay;
    } else {
      currentGroup.endDay = getPreviousWeekDay(currentDay);
      currentGroup = {
        startDay: currentDay,
        endDay: currentDay,
        slots: currentSlots,
      };
      groups.push(currentGroup);
    }
    currentDay = getNextWeekDay(currentDay);
  } while (currentDay !== startOfWeek);
  return groups;
};
