import React, { useMemo } from 'react';
import { PbcPartner } from './components/PartnerData';

export interface PartnerProviderProps {
  partner: PbcPartner;
  children?: React.ReactNode;
}

export interface IPartnerContext {
  partner: PbcPartner;
  hasOpeningHours: boolean;
}

export const PartnerContext = React.createContext<IPartnerContext>({
  partner: {
    partnerId: '',
    officialName: '',
    nonOfficialName: '',
    address: [''],
    locationImageUrl: '',
    vCardUrl: '',
    myAudiUrl: '',
    directionsUrl: '',
    services: [''],
    nameEnhanced: '',
  },
  hasOpeningHours: false,
});

export const PartnerProvider: React.FC<PartnerProviderProps> = ({ partner, children }) => {
  const hasOpeningHours = !!(
    partner.openingHours?.departments && partner.openingHours?.departments?.length > 0
  );

  return useMemo(
    () => (
      <PartnerContext.Provider value={{ partner, hasOpeningHours }}>
        {children}
      </PartnerContext.Provider>
    ),
    [children, partner, hasOpeningHours],
  );
};
