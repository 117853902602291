import { Country } from './country';

function isDefinedISO2CountryCode(key: unknown): key is keyof typeof Country {
  return typeof key === 'string' && key in Country;
}

export const toISO3CountryCodeDealerFormatter = (countryCode: string, partnerId: string) => {
  const countryCodeCapitalized = countryCode.toUpperCase();
  if (isDefinedISO2CountryCode(countryCodeCapitalized)) {
    return `${Country[countryCodeCapitalized]}A${partnerId}`;
  }
  return undefined;
};
