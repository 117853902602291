/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line
import { ContentFragment } from '@oneaudi/falcon-tools';

export interface FalconContent extends Partial<ContentFragment> {
  [x: string]: any;
  __type: 'aem-headless';
  fields: {
    [x: string]: string;
    partnerID: string;
    showVariant: string;
    variantConfig: any;
  };
}

export interface EditorJsonContent {
  __type?: 'editor.json';
  partnerID: string;
  showVariant: string;
  variantConfig: {
    contactDetails: {
      displayOpeningHours: boolean;
      displayCtaBar: boolean;
      displayOfficialName: boolean;
      displayPhone: boolean;
      displayEmail: boolean;
      isLinkedToPWS: boolean;
      showMoreInfoLink: boolean;
      hasOuterSpacing: boolean;
      isGoogleMapLink: boolean;
      showImage: boolean;
      showServiceList: boolean;
      showJsonLd: boolean;
    };
    socialMedia: [
      {
        facebook: boolean;
        facebookUrl: string;
      },
      {
        instagram: boolean;
        instagramUrl: string;
      },
      {
        twitter: boolean;
        twitterUrl: string;
      },
      {
        linkedln: boolean;
        linkedlnUrl: string;
      },
      {
        youtube: boolean;
        youtubeUrl: string;
      },
      {
        vimeo: boolean;
        vimeoUrl: string;
      },
    ];
    ctaBarButtonConfig: [
      {
        label: 'string';
        variant: 'string';
        url: 'string';
      },
      {
        label: 'string';
        variant: 'string';
        url: 'string';
      },
    ];
  };

  // eslint-disable-next-line
  pbcPartnerID: string;
  pbcShowVariant: string;
  variantConfigHeadless: any;
}

export function mapContent(content: EditorJsonContent | FalconContent): EditorJsonContent {
  if (!content || Object.keys(content).length === 0) {
    console.warn('No Editor content found for Partner Business Card'); // TODO: Replace console.warn with use of @feature-hub/logger
    return {} as EditorJsonContent;
  }

  if (content.__type !== 'aem-headless') {
    return content;
  }
  // eslint-disable-next-line
  let pbcPartnerID = content?.fields?.partnerID || '';

  // eslint-disable-next-line
  let pbcShowVariant = content?.fields?.showVariant || '';

  // eslint-disable-next-line
  let ctaBarButtonConfigArray = [
    {
      label: content?.fields?.variantConfig_ctaBarButtonConfig_0_label || '',
      url: content?.fields?.variantConfig_ctaBarButtonConfig_0_url || '',
      variant: content?.fields?.variantConfig_ctaBarButtonConfig_0_variant || '',
    },
    {
      label: content?.fields?.variantConfig_ctaBarButtonConfig_1_label || '',
      url: content?.fields?.variantConfig_ctaBarButtonConfig_1_url || '',
      variant: content?.fields?.variantConfig_ctaBarButtonConfig_1_variant || '',
    },
  ];

  const socialMediaNameAndUrlStatus: any = content?.fields?.variantConfig_socialMedia;
  const getSocialMediaValueContent = socialMediaNameAndUrlStatus?.map((item: any) => ({
    facebook: item.fields.facebook,
    facebookUrl: item.fields.facebookUrl,
    instagram: item.fields.instagram,
    instagramUrl: item.fields.instagramUrl,
    twitter: item.fields.twitter,
    twitterUrl: item.fields.twitterUrl,
    linkedln: item.fields.linkedln,
    linkedlnUrl: item.fields.linkedlnUrl,
    youtube: item.fields.youtube,
    youtubeUrl: item.fields.youtubeUrl,
    vimeo: item.fields.vimeo,
    vimeoUrl: item.fields.vimeoUrl,
  }));

  // eslint-disable-next-line
  const variantConfigHeadless = {
    ctaBarButtonConfig: ctaBarButtonConfigArray || '',
    socialMedia: getSocialMediaValueContent || '',
    displayCtaBar: content?.fields?.variantConfig_contactDetails_displayCtaBar || '',
    displayEmail: content?.fields?.variantConfig_contactDetails_displayEmail || '',
    displayOfficialName: content?.fields?.variantConfig_contactDetails_displayOfficialName || '',
    displayOpeningHours: content?.fields?.variantConfig_contactDetails_displayOpeningHours || '',
    displayPhone: content?.fields?.variantConfig_contactDetails_displayPhone || '',
    hasOuterSpacing: content?.fields?.variantConfig_contactDetails_hasOuterSpacing || '',
    isGoogleMapLink: content?.fields?.variantConfig_contactDetails_isGoogleMapLink || '',
    isLinkedToPWS: content?.fields?.variantConfig_contactDetails_isLinkedToPWS || '',
    showImage: content?.fields?.variantConfig_contactDetails_showImage || '',
    showJsonLd: content?.fields?.variantConfig_contactDetails_showJsonLd || '',
    showMoreInfoLink: content?.fields?.variantConfig_contactDetails_showMoreInfoLink || '',
    showSocialMediaLinks: content?.fields?.variantConfig_contactDetails_showSocialMediaLinks || '',
    showServiceList: content?.fields?.variantConfig_contactDetails_showServiceList || '',
  };
  const normalizedContent: EditorJsonContent = {
    ...content.fields,
    pbcPartnerID,
    pbcShowVariant,
    variantConfigHeadless,
  };

  return normalizedContent;
}
