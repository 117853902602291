import * as React from 'react';
import { LayoutItem } from '@audi/audi-ui-react';
import { useTheme } from 'styled-components';
import { OpeningHours } from './OpeningHours';
import { AddressAndContact } from './AddressAndContact';
import { VariantConfig } from './PartnerBusinessCard';
import { PartnerBusinessCardHeader } from './PartnerBusinessCardHeader';
import { JsonLd } from './Json-ld';
import { PartnerContext } from '../PartnerProvider';
import { useMedia } from '../../lib/hooks';

export const PartnerBusinessCardFixedNarrow = (
  variantConfig: VariantConfig,
): React.ReactElement => {
  const { partner, hasOpeningHours } = React.useContext(PartnerContext);

  const { ctaBarButtonConfig = [] } = variantConfig;
  const { socialMedia = [] } = variantConfig;
  const {
    displayCtaBar,
    displayOfficialName,
    displayEmail,
    displayOpeningHours,
    displayPhone,
    isGoogleMapLink,
    isLinkedToPWS,
    showMoreInfoLink,
    showImage,
    showServiceList,
    showJsonLd,
    showSocialMediaLinks,
    // eslint-disable-next-line
  } = variantConfig.contactDetails || variantConfig;

  const theme = useTheme();
  const isScreenWidthAtLeastM = useMedia(`only screen and (min-width: ${theme.breakpoints.m}px)`);
  return (
    partner && (
      <>
        {showJsonLd && <JsonLd data={partner} />}
        <PartnerBusinessCardHeader
          partner={partner}
          isNarrow
          displayOfficialName={displayOfficialName}
          showImage={showImage}
          showServiceList={showServiceList}
          isLinkedToPWS={isLinkedToPWS}
        />
        <LayoutItem spaceStackStart={isScreenWidthAtLeastM ? 'xl' : 'xxxl'}>
          <AddressAndContact
            isNarrow
            isLinkedToPWS={isLinkedToPWS}
            displayEmail={displayEmail}
            displayPhone={displayPhone}
            isGoogleMapLink={isGoogleMapLink}
            showMoreInfoLink={showMoreInfoLink}
            displayCtaBar={displayCtaBar}
            ctaBarButtonConfig={ctaBarButtonConfig}
            showSocialMediaLinks={showSocialMediaLinks}
            socialMedia={socialMedia}
          />
        </LayoutItem>

        {displayOpeningHours && hasOpeningHours && (
          <LayoutItem spaceStackStart="xxl">
            <OpeningHours data={partner} isNarrow />
          </LayoutItem>
        )}
      </>
    )
  );
};
