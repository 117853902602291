import { Button, Text } from '@audi/audi-ui-react';
import styled from 'styled-components';

export const Container = styled.dialog``;

export const ImprintContainer = styled.div`
  background-color: #fff;
  height: fit-content;
  margin: 0 auto;
  max-width: 840px;
  padding: 90px 66px 78px;
  position: relative;
  width: auto;
`;

export const Content = styled.div`
  display: grid;
  height: 100%;
`;

export const CloseButton = styled(Button)`
  align-self: baseline;
  justify-self: end;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

export const Title = styled(Text)`
  margin-bottom: 54px;
`;

export const ImprintText = styled(Text)`
  color: #000;
`;
